import React from 'react';
import { Img, Neon } from '../../components';
import { Content, Description, FeaturedImg, Wrapper } from './Bio.styled';

export default function Bio({ data }) {
	const { title, description } = data.site.siteMetadata;
	return (
		<Wrapper>
			<Neon as="h1" color="blue">
				About
			</Neon>
			<Content>
				<FeaturedImg>
					<Img
						src={data.file.childImageSharp.gatsbyImageData}
						alt={title}
					/>
					<div className="overlay"></div>
				</FeaturedImg>
				<Description>
					{description}{' '}
					<a
						href="https://www.instagram.com/guitarlos/"
						target="_blank"
						rel="noopener noreferrer"
						className="instagram"
						title="instagram.com/guitarlos"
					>
						@guitarlos
					</a>
				</Description>
			</Content>
		</Wrapper>
	);
}
