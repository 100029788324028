import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import { isFutureDate, slugify } from '../../utils';
import { Arrow } from '../../components';
import {
	Time,
	Live,
	Band,
	Emoji,
	Place,
	Address,
	GigStyles,
} from './Gig.styled';

// Here are a few improvements for the Gig component:

// Memoize the handleEmoji function: This will prevent the function from being recreated on every render.
// Destructure the gig prop: This will make the code cleaner.
// Use useMemo for formattedAddress and currentDate: This will optimize the performance by memoizing these values.

export default function Gig({ gig }) {
	const { band, date, place, address, visible } = gig;
	const formattedAddress = useMemo(
		() => address.replace(/,/, '\n'),
		[address]
	);
	const now = Date.now();
	const currentDate = useMemo(() => new Date(date).getTime(), [date]);
	const upcomingShow = isFutureDate(date, 3);

	const handleEmoji = useMemo(() => {
		const emojiMap = {
			'Los Toasters': '🍞',
			"Rosedale Ramblin' Band": '🌹',
		};
		return emojiMap[band] || '🎸';
	}, [band]);

	return (
		visible && (
			<GigStyles>
				{upcomingShow && currentDate <= now && (
					<Link
						className="button live-link"
						to={`/projects/${slugify(band)}`}
					>
						<Live>
							<span> Rockin' </span>
							<span className="emoji"> 🎸 </span>
						</Live>
					</Link>
				)}
				<Time>{date}</Time>
				<Place>{place}</Place>
				<Address>{formattedAddress}</Address>
				<Link className="band-link" to={`/projects/${slugify(band)}`}>
					<Emoji>{handleEmoji}</Emoji>
					<Band>{band}</Band>
					<Arrow />
				</Link>
			</GigStyles>
		)
	);
}
