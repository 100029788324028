import React from 'react';
import { Back, Img, Neon } from '../../components';
import { Body, Content, FeaturedImg, Wrapper } from './Project.styled';

export default function Project({ data, children }) {
	const { title, image } = data.mdx.frontmatter;
	return (
		<Wrapper className="container">
			<Back title="Back to projects" />
			<Neon as="h1" color="flicker" className="center">
				{title}
			</Neon>
			<Content>
				<FeaturedImg>
					<Img
						src={image.childImageSharp.gatsbyImageData}
						alt={title}
					/>
					<div className="overlay" />
				</FeaturedImg>
				<Body>{children}</Body>
			</Content>
		</Wrapper>
	);
}
