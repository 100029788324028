import styled from 'styled-components';

export const ArrowWrapper = styled.span`
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-family: var(--headingFont);
`;

export const ArrowStatic = styled.span``;

export const AnimatedArrow = styled.span`
	@keyframes slide {
		from {
			transform: translateX(0);
		}

		to {
			transform: translateX(0.25rem);
		}
	}

	animation-name: slide;
	animation-duration: 0.4s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in;
	animation-play-state: running;
	animation-direction: alternate;
`;
