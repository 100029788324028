import React from 'react';
import { Link } from 'gatsby';
import { Img, Neon } from '../../components';

import {
	Time,
	List,
	Wrapper,
	Content,
	Heading,
	Subheading,
	ImageWrapper,
} from './Tiles.styled';

export default function Tiles({ data, title, color }) {
	return (
		<Wrapper>
			{title && (
				<Heading>
					<Neon color={color}>{title}</Neon>
				</Heading>
			)}
			{data && (
				<List>
					{data.map(
						item =>
							item.frontmatter.status === 'public' && (
								<li key={item.id}>
									<Link
										to={`/${
											item.frontmatter.type === 'post'
												? 'blog'
												: 'projects'
										}/${item.frontmatter.slug}`}
									>
										<ImageWrapper>
											<Img
												src={
													item.frontmatter.image
														.childImageSharp
														.gatsbyImageData
												}
												alt={item.frontmatter.title}
											/>
											<div className="overlay"></div>
										</ImageWrapper>
										<Content>
											<Subheading>
												{item.frontmatter.title}
											</Subheading>
											{item.frontmatter.type ===
												'post' && (
												<Time>
													{item.frontmatter.date}
												</Time>
											)}
										</Content>
									</Link>
								</li>
							)
					)}
				</List>
			)}
		</Wrapper>
	);
}
