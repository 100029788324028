import styled from 'styled-components';

export const Tag = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--color);
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: var(--headerHeight);
	background: var(--headerBackground);
	padding: 0 var(--containerPadding);
	font-family: var(--sans-serif);
	transition: background 0.5s ease-in;
	z-index: 2;

	&.header--active {
		background: rgb(1 1 10 / 75%);
	}
`;
