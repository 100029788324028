import styled from 'styled-components';

export const Wrap = styled.footer`
	padding: var(--containerPadding);
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: var(--gap);
	height: var(--footerHeight);

	.instagram {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: calc(var(--gap) / 2);
	}
`;

export const Copyright = styled.small`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: calc(var(--gap) / 3);
`;
