import React from 'react';
import { Icon } from '../../components';
import { Copyright, Wrap } from './Footer.styled';

export default function Footer() {
	return (
		<Wrap>
			<a
				href="https://www.instagram.com/guitarlos/"
				target="_blank"
				rel="noopener noreferrer"
				className="instagram"
				title="instagram.com/guitarlos"
			>
				<Icon type="instagram" size="2rem" /> @guitarlos
			</a>
			<Copyright>
				<span> &copy;</span> <span>{new Date().getFullYear()} </span>
			</Copyright>
		</Wrap>
	);
}
