import React from 'react';
import { Header, Footer } from '../../components';
import 'normalize.css';
import '../../styles/global.css';
import { LayoutStyles } from './Layout.styled';

export default function Layout({ location, children }) {
	return (
		<LayoutStyles
			id={
				location.pathname === '/'
					? 'home'
					: location.pathname.replace(/\\|\//g, '')
			}
		>
			<Header />
			<main>{children}</main>
			<Footer />
		</LayoutStyles>
	);
}
