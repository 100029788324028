import React from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { links } from './links';
import { Icon } from '../../components';
import { Item, List, Overlay, Tag } from './Nav.styled';

export default function Nav({ isNavOpen, onClose }) {
	const { pathname } = useLocation();
	return (
		<Tag $isNavOpen={isNavOpen} aria-expanded={isNavOpen}>
			{isNavOpen && <Overlay onClick={onClose} />}
			{links && (
				<List>
					{links.map((link, i) => (
						<Item key={i}>
							<Link
								to={link.route}
								className={
									pathname.includes(link.route)
										? 'active'
										: undefined
								}
							>
								<span>{link.title}</span>
							</Link>
						</Item>
					))}
					<Item>
						<a
							href="https://www.instagram.com/guitarlos/"
							target="_blank"
							rel="noopener noreferrer"
							className="instagram"
							title="@guitarlos"
						>
							<Icon type="instagram" size="2rem" />
						</a>
					</Item>
				</List>
			)}
		</Tag>
	);
}
