import styled from 'styled-components';

export const LayoutStyles = styled.div`
	display: flex;
	flex-direction: column;
	/* https://stackoverflow.com/questions/68094609/ios-15-safari-floating-address-bar */
	/* https://stackoverflow.com/a/75648985 */
	/* https://developer.mozilla.org/en-US/docs/Web/CSS/length */
	min-height: 100dvh;
`;
