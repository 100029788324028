import React, { useEffect, useState } from 'react';
import { Logo } from '../Logo';
import { Tag } from './Header.styled';
import { Menu } from '../../components';

export default function Header() {
	const [scrollY, setScrollY] = useState(0);
	const isActive = scrollY > 54;

	function handleScroll() {
		// Change Header background color.
		setScrollY(window.scrollY);
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const activeClass = isActive ? 'header--active' : 'header--inactive';

	return (
		<Tag className={activeClass}>
			<Logo />
			<Menu scrollY={scrollY} />
		</Tag>
	);
}
