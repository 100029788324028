import styled from 'styled-components';
import { media } from '../../utils';

export const Wrapper = styled.article`
	ul {
		list-style: none;
		padding-left: 0;
	}
`;

export const Body = styled.article`
	p:first-child:first-letter {
		font: normal 5rem var(--serif);
		float: left;
		margin: var(--lineHeight) var(--lineHeight) 0 0;
		line-height: 0.65;
	}
`;

export const Facts = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const FeaturedImg = styled.div`
	overflow: hidden;
	height: fit-content;
	background: var(--photoBg);
	padding: 0.5rem;
	position: relative;

	.overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	${media.medium`
		padding: 0.75rem;
	`}
`;

export const Content = styled.div`
	position: relative;
	display: grid;
	gap: var(--containerPadding);
	max-width: 35rem;
	margin: auto;
`;
