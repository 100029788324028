import { Link } from 'gatsby';
import React from 'react';
import { Arrow, Img, Neon } from '../../components';

import { Wrapper, Overlay, FeaturedImg, Content } from './Home.styled';

export default function Home({ data }) {
	const { title } = data.site.siteMetadata;

	return (
		<Wrapper>
			<FeaturedImg>
				<Img
					src={data.file.childImageSharp.gatsbyImageData}
					alt={title}
				/>
			</FeaturedImg>
			<Overlay />
			<Content>
				<Link to="/gigs">
					<Neon as="h1" color="blue-marquee">
						{title}
					</Neon>
					<h2>
						<Neon color="blue">Enter</Neon>{' '}
						<Neon color="blue-flicker">
							Here <Arrow />
						</Neon>
					</h2>
				</Link>
			</Content>
		</Wrapper>
	);
}
