import styled, { css } from 'styled-components';
import { media } from '../../utils';

export const Tag = styled.nav`
	text-align: center;
	font-size: 1.5rem;
	max-width: 1440px;
	padding: 0;
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100vh;
	transition: all 400ms;
	transform: translateX(calc(100% + var(--gap)));

	${media.medium`
		position: relative;
		justify-content: flex-end;
		align-items: center;
		font-size: inherit;
		height: auto;
		transform: translateX(0);
	`}

	${props =>
		props.$isNavOpen &&
		css`
			transform: translateX(0);
		`}

	a {
		--target: calc((var(--gap) / 2) * -1);
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		&.active {
			> span {
				position: relative;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;

				&:after {
					content: '';
					position: absolute;
					bottom: -0.25rem;
					left: 0;
					background: var(--color);
					height: 2px;
					width: 100%;

					${media.medium`
						bottom: -0.25rem;
					`}
				}
			}
		}

		&:before {
			content: '';
			position: absolute;
			top: var(--target);
			right: var(--target);
			bottom: var(--target);
			left: var(--target);
		}
	}
`;

export const List = styled.ul`
	list-style: none;
	padding: 0;
	background: var(--headerBackground);
	margin-top: var(--headerHeight);
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	margin: 0;
	justify-content: center;

	${media.medium`
		flex-direction: row;
		gap: var(--gap);
		height: auto;
		margin: auto;
	`}
`;

export const Item = styled.li`
	line-height: 1.2;
	width: 100%;
	display: inline-block;
`;

export const Overlay = styled.div`
	background: rgb(0 0 0 / 90%);
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	z-index: -1;

	${media.medium`
		display: none;
	`}
`;
