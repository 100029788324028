import React from 'react';
import { Img } from '../../components';
import { Wrapper } from './Banner.styled';

function Shades({ shades }) {
	return (
		<div className="shades">
			{Array.from(Array(shades), (_, i) => {
				return <div key={i} />;
			})}
		</div>
	);
}

export default function Banner({ image, title, shades = 6 }) {
	return (
		<Wrapper>
			<Img src={image.childImageSharp.gatsbyImageData} alt={title} />
			<Shades shades={shades} />
			<div className="banner_content">
				<h1 className="title">{title}</h1>
			</div>
		</Wrapper>
	);
}
