/**
 *
 * @param {String} text
 * @returns {String}
 */
export function slugify(text) {
	return text
		.toLowerCase()
		.replace(/[^a-z0-9\s-]/g, '') // Remove special characters except spaces and hyphens
		.replace(/\s+/g, '-') // Replace spaces with hyphens
		.replace(/--+/g, '-') // Replace multiple consecutive hyphens with a single hyphen
		.trim(); // Remove leading/trailing hyphens
}
