import styled from 'styled-components';
import { media } from '../../utils';

export const Wrapper = styled.div`
	max-width: 45rem;
	margin: auto;

	h1 {
		text-align: center;
	}

	.gatsby-image-wrapper {
		height: 100%;
	}
`;

export const Content = styled.div``;

export const List = styled.ul`
	list-style: none;
	padding: 0;
	text-align: center;
	display: grid;
	gap: var(--gap);
	grid-auto-rows: 1fr;

	${media.small`	
		grid-template-columns: repeat(2, 1fr);
	`}

	${media.large`	
		grid-template-columns: repeat(3, 1fr);
	`}
`;

export const Heading = styled.h1`
	text-align: center;
`;

export const Subheading = styled.h2`
	text-align: center;
	margin: 20px auto 0px;
	font-size: larger;
`;

export const ImageWrapper = styled.div`
	overflow: hidden;
	height: 300px;
	padding: 0.5rem;
	background: var(--color);
	position: relative;

	.overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	${media.large`	
		height: 260px;
	`}
`;

export const Time = styled.time`
	color: var(--gray);
`;
