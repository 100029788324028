import styled from 'styled-components';
import { media } from '../../utils';

export const Wrapper = styled.article`
	max-width: 65rem;
	margin: auto;

	h1 {
		text-align: center;
	}
`;

export const Content = styled.div`
	position: relative;
	display: grid;
	gap: var(--containerPadding);
	max-width: 35rem;
	margin: auto;
`;

export const FeaturedImg = styled.div`
	overflow: hidden;
	height: fit-content;
	background: var(--photoBg);
	padding: 0.5rem;
	position: relative;

	${media.medium`
		padding: 0.75rem;
	`}

	.gatsby-image-wrapper {
		height: 500px;

		${media.small`
			height: 850px;
		`}
	}

	.overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
`;

export const Description = styled.p`
	&:first-letter {
		font: normal 5rem var(--serif);
		float: left;
		margin: var(--lineHeight) var(--lineHeight) 0 0;
		line-height: 0.65;
	}

	a {
		margin-top: 1rem;
	}
`;
