import React, { useEffect, useLayoutEffect, useState } from 'react';
import Hamburger from './Hamburger';
import { Nav } from '../Nav';
import { HamburgerWrapper, MenuStyles } from './Menu.styled';
import { globalHistory } from '@reach/router';

export default function Menu() {
	const [isNavOpen, setIsNavOpen] = useState(false);

	useLayoutEffect(() => {
		if (isNavOpen) {
			document.body.style.overflow = 'hidden';
		}

		return () => {
			document.body.style.overflow = '';
		};
	}, [isNavOpen]);

	useEffect(() => {
		// https://stackoverflow.com/questions/61274365/allow-component-to-detect-route-change-in-gatsby
		// https://stackoverflow.com/a/61664193
		return globalHistory.listen(({ action }) => {
			if (action === 'PUSH') setIsNavOpen(false);
		});
	}, [setIsNavOpen]);

	return (
		<MenuStyles>
			<Nav
				isNavOpen={isNavOpen}
				onClose={() => {
					setIsNavOpen(false);
				}}
			/>
			<HamburgerWrapper>
				<Hamburger
					color={'var(--color)'}
					isOpen={isNavOpen}
					menuClicked={() => {
						setIsNavOpen(!isNavOpen);
					}}
					width={27}
					height={18}
				/>
			</HamburgerWrapper>
		</MenuStyles>
	);
}
