import styled from 'styled-components';
import { media } from '../../utils';

export const Wrapper = styled.div`
	display: grid;
	width: 100vw;
	/* https://stackoverflow.com/questions/68094609/ios-15-safari-floating-address-bar */
	/* https://stackoverflow.com/a/75648985 */
	/* https://developer.mozilla.org/en-US/docs/Web/CSS/length */
	height: 100dvh;

	${media.medium`
		height: 100vh;
	`}

	.gatsby-image-wrapper {
		position: initial;
		height: inherit;
	}
`;

export const FeaturedImg = styled.div`
	height: inherit;
	grid-row: 1;
	grid-column: 1;
	z-index: -1;
`;

export const Overlay = styled.div`
	--opacity: 60%;
	background: rgb(0 0 0 / var(--opacity));
	height: inherit;
	grid-row: 1;
	grid-column: 1;

	${media.medium`
		--opacity: 75%;
	`}
`;

export const Lights = styled.div`
	--opacity: 70%;
	background: rgb(0 0 0 / var(--opacity));
	height: inherit;
	grid-row: 1;
	grid-column: 1;
	animation: 3s stage_lights infinite;

	@keyframes stage_lights {
		0% {
			background: linear-gradient(
				90deg,
				rgba(255, 0, 0, 0.05) 50%,
				rgba(255, 0, 0, 0.05) 0%
			);
		}
		50% {
			background: linear-gradient(
				90deg,
				rgba(0, 0, 255, 0.05) 50%,
				rgba(0, 0, 255, 0.05) 0%
			);
		}

		55% {
			background: linear-gradient(
				90deg,
				rgba(0, 255, 0, 0.025) 50%,
				rgba(0, 255, 0, 0.025) 0%
			);
		}
		80% {
			background: linear-gradient(
				90deg,
				rgba(0, 0, 255, 0.05) 50%,
				rgba(0, 0, 255, 0.05) 0%
			);
		}

		85% {
			background: linear-gradient(
				90deg,
				rgba(0, 255, 0, 0.025) 50%,
				rgba(0, 255, 0, 0.025) 0%
			);
		}
		100% {
			background: linear-gradient(
				90deg,
				rgba(255, 0, 0, 0.05) 50%,
				rgba(255, 0, 0, 0.05) 0%
			);
		}
	}
`;

export const Content = styled.div`
	text-align: center;
	height: inherit;
	grid-row: 1;
	grid-column: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: var(--gap);
	z-index: 1;
`;
