import styled from 'styled-components';

export const GigStyles = styled.li`
	text-align: center;
	border-bottom: var(--line);
	max-width: 420px;
	margin: auto;
	padding: 1rem 0;
	display: flex;
	flex-direction: column;
	gap: var(--gap);

	&:last-child {
		border-bottom: none;
	}

	.live-link {
		margin: 0 auto;

		.emoji {
			transition: all 0.3s ease-out;
		}

		&:hover .emoji {
			transform: rotate(30deg);
		}
	}

	.band-link {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: calc(var(--gap) / 2);
	}
`;

export const Place = styled.h2`
	font-family: inherit;
	font-size: larger;
	font-weight: bold;
	margin: 0;
`;

export const Address = styled.address`
	line-height: 1.5;
	white-space: pre;
`;

export const Time = styled.time`
	margin: 0;
`;

export const Band = styled.span`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

export const Emoji = styled.span``;

export const Live = styled.div`
	font-family: var(--headingFont);
	font-weight: 400;
	letter-spacing: 0.2rem;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: calc(var(--gap) / 2);
	border-radius: var(--borderRadius);

	&:before {
		content: '';
		animation: live_pulse 2s infinite ease-in-out;
		border-radius: 50%;
		background: var(--danger);
		height: calc(var(--gap) / 2.5);
		width: calc(var(--gap) / 2.5);
	}

	@keyframes live_pulse {
		0%,
		100% {
			opacity: 1;
		}

		50% {
			opacity: 0.33;
		}
	}
`;

export const Blinker = styled.span`
	color: var(--danger);
	animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;

	@keyframes blinker {
		from {
			opacity: 1;
		}
		to {
			opacity: 0.3;
		}
	}
`;
