import styled from 'styled-components';

// https://www.drivencreativelymad.com/blog/create-a-simple-call-to-action-button

export const ButtonWrapper = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;

	a {
		text-align: center;
		border: none;
		border-radius: 3em;
		display: inline-block;
		font-size: 1em;
		padding: 1em 2em;
		width: auto;
		text-transform: capitalize;
		line-height: 1;
		transition: all ease 0.3s;
		background-color: var(--cardBg);
		color: var(--color);
		position: relative;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		gap: calc(var(--gap) / 2);

		.arrow {
			transition: transform ease 0.3s;
		}

		&:hover {
			background-color: var(--cardBgHover);

			& .arrow:last-child {
				transform: translateX(0.25rem);
			}
		}
	}
`;

export const Text = styled.span`
	display: inline-block;
`;
