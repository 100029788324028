export const links = [
	{
		title: 'Gigs',
		route: '/gigs',
	},
	{
		title: 'Projects',
		route: '/projects',
	},
	{
		title: 'Blog',
		route: '/blog',
	},
	{
		title: 'About',
		route: '/about',
	},
];
