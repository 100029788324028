import React from 'react';
import { Arrow } from '..';
import { Text, ButtonWrapper } from './CTA.styled';
import { Link } from 'gatsby';

export default function CTALink({ to, href, text, arrow, animated }) {
	return (
		<ButtonWrapper>
			{href ? (
				<a href={href} target="_blank" rel="noopener noreferrer">
					<Text>{text}</Text>
					{arrow && <Arrow animated={animated} />}
				</a>
			) : (
				<Link to={to}>
					<Text>{text}</Text>
					{arrow && <Arrow animated={animated} />}
				</Link>
			)}
		</ButtonWrapper>
	);
}
