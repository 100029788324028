import React from 'react';
import { AnimatedArrow, ArrowStatic, ArrowWrapper } from './Arrow.styled';

export default function Arrow({ animated }) {
	return (
		<ArrowWrapper className="arrow">
			{animated ? (
				<AnimatedArrow> &rarr; </AnimatedArrow>
			) : (
				<ArrowStatic> &rarr; </ArrowStatic>
			)}
		</ArrowWrapper>
	);
}
